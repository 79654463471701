<template>
  <v-container fluid>
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          CARGANDO...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row
      v-if="!carga"
      justify="center"
    >
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Información sobre juzgados
            </div>
            <div class="text-subtitle-1 font-weight-light">
              {{ dataUsuario.nombre }}, actualice la información sobre los juzgados de su corte
            </div>
          </template>
          <v-row justify="center">
            <v-col
              cols="12"
              md="9"
            >
              <v-card-title>
                <v-btn
                  color="red"
                  fab
                  x-small
                  dark
                  @click="addJuzgado()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn> <span class="text-subtitle-1 font-weight-light">Nuevo juzgado</span>
                <v-spacer />
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="ml-auto"
                  label="Buscar..."
                  hide-details
                  single-line
                  style="max-width: 250px;"
                />
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                dense
                class="elevation-1"
                fixed-header
                :loading="carga"
                loading-text="Cargando, por favor espere"
                :footer-props="{
                  'items-per-page-options': [10, 25, 50, -1],
                  'items-per-page-text': 'Mostrando:',
                  'items-per-page-all-text': 'Todos'
                }"
                no-data-text="No hay datos registrados"
                no-results-text="No se encontraron resultados"
              >
                <template v-slot:[`item.editar`]="{ item }">
                  <v-btn
                    color="green"
                    fab
                    x-small
                    dark
                    @click="editarJuzgado(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.sala`]="{ item }">
                  <span
                    v-if="parseInt(item.es_sala) === 1"
                  ><v-chip
                    class="ma-2"
                    color="green"
                    label
                    text-color="white"
                  >
                    <v-icon>
                      mdi-scale-balance
                    </v-icon>
                  </v-chip></span>
                  <span
                    v-else
                  ><v-chip
                    class="ma-2"
                    color="blue"
                    label
                    text-color="white"
                  >
                    <v-icon>
                      mdi-gavel
                    </v-icon>
                  </v-chip></span>
                </template>
              </v-data-table>
            </v-col>
            <div
              class="text-center"
            >
              <v-form
                ref="form"
                lazy-validation
              >
                <v-dialog
                  v-model="dialogJuzgado"
                  width="800"
                  persistent
                >
                  <v-card>
                    <v-card-title class="text-h3 grey lighten-2">
                      Datos del juzgado
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="idJuzgado"
                            label="ID"
                            prepend-icon="mdi-fingerprint"
                            :disabled="updateJuzgado"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="10"
                        >
                          <v-text-field
                            v-model="nombreJuzgado"
                            label="Nombre"
                            prepend-icon="mdi-account-tie"
                            :rules="[(v) => !!v || 'Campo requerido']"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <v-checkbox
                            v-model="checkSala"
                            :label="`¿Es sala?: ${checkSala === true ? 'SI' : 'NO'}`"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <v-checkbox
                            v-model="checkEstado"
                            :label="`Estado: ${checkEstado === true ? 'Activo' : 'Desactivado'}`"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="nombreJuez"
                            label="Juez"
                            prepend-icon="mdi-scale-balance"
                            :rules="[(v) => !!v || 'Campo requerido']"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider />

                    <v-card-actions>
                      <v-btn
                        v-if="updateJuzgado"
                        color="primary"
                        text
                        @click="actualizarJuzgado()"
                      >
                        {{ textoBoton }}
                      </v-btn>

                      <v-btn
                        v-else
                        color="primary"
                        text
                        @click="addNewJuzgado()"
                      >
                        {{ textoBoton }}
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        @click="cerrarDialog()"
                      >
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-form>
            </div>
            <v-col
              cols="12"
              md="9"
            >
              <b>Si deseas inicializar tus juzgados o añadir en bloque, adjunte el excel. </b>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-file-input
                v-model="archivoCsv2"
                :rules="reglaUpload"
                show-size
                placeholder="Ingrese juzgados por bloque"
                prepend-icon="mdi-microsoft-excel"
                clear-icon
                label="Adjunte el archivo de juzgados"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              style="text-align: right"
            >
              <v-btn
                tile
                color="success"
                @click="updateResumen(archivoCsv2)"
              >
                <v-icon left>
                  mdi-upload
                </v-icon>
                Subir
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />
          <v-spacer />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import XLSX from 'xlsx'
  export default {
    name: 'Upload',
    data: () => ({
      reglaUpload: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          'El archivo no debe pesar más de 5 MB',
      ],
      usuario: '',
      nombreUsuario: '',
      mesUpdate: '',
      sedeCorteData: '',
      carga: false,
      headers: [
        {
          text: 'Editar',
          value: 'editar',
        },
        {
          text: 'Nombre',
          value: 'nombre',
        },
        {
          text: 'Tipo de juzgado',
          value: 'sala',
        },
        {
          text: 'Juez',
          value: 'juez',
        },
      ],
      archivoCsv2: [],
      dataUpdate: [],
      urlDataSede: '',
      sumaI: 0,
      incorrectos: 0,
      urlLogo: '',
      urlJuzgados: '',
      items: [],
      search: '',
      dialogJuzgado: false,
      idJuzgado: '',
      nombreJuzgado: '',
      checkSala: false,
      checkEstado: false,
      nombreJuez: '',
      dataJuzgado: {},
      textoBoton: 'ACTUALIZAR',
      updateJuzgado: false,
      nuevoJuzgado: false,
      nuevoForm: false,
    }),
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (!this.dataUsuario || parseInt(this.dataUsuario.es_admin) !== 1) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.$store.commit('SET_VALOR', false)
      this.$store.commit('SET_LOGO', true)
      this.urlJuzgados = `${window.__env.dataCfg.urlApiData}juzgados/${this.dataUsuario.sede}/`
      this.leerJuzgados()
      console.log(this.dataUsuario.sede)
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      actualizarJuzgado () {
        if (this.$refs.form.validate()) {
          const data = {
            id: this.idJuzgado,
            nombre: this.nombreJuzgado,
            es_sala: this.checkSala ? 1 : 0,
            estado: this.checkEstado ? 1 : 0,
            juez: this.nombreJuez,
            sede: this.dataUsuario.sede,
          }
          axios
            .put(`${window.__env.dataCfg.urlApiData}juzgado/`, data)
            .then((r) => {
              r.data.resp === true ? alert('Se actualizó el juzgado') : alert('No se actualizó')
              this.leerJuzgados()
              this.cerrarDialog()
            })
            .catch((err) => {
              alert('Servicio no disponible' + err)
            })
        }
      },
      leerJuzgados () {
        axios
          .get(
            this.urlJuzgados,
          )
          .then((response) => {
            this.items = response.data.array
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      },
      addJuzgado () {
        this.updateJuzgado = false
        this.nuevoJuzgado = true
        this.textoBoton = 'AÑADIR'
        this.dialogJuzgado = true
      },
      addNewJuzgado () {
        if (this.$refs.form.validate()) {
          const data = {
            id: this.idJuzgado,
            nombre: this.nombreJuzgado,
            es_sala: this.checkSala ? 1 : 0,
            estado: this.checkEstado ? 1 : 0,
            juez: this.nombreJuez,
            sede: this.dataUsuario.sede,
          }
          this.uploadInfo(data, 1)
        }
      },
      editarJuzgado (item) {
        this.textoBoton = 'ACTUALIZAR'
        this.updateJuzgado = true
        this.nuevoJuzgado = false
        this.dialogJuzgado = true
        axios
          .get(
            `${window.__env.dataCfg.urlApiData}juzgado_e/${item.id}_${this.dataUsuario.sede}/`,
          )
          .then((response) => {
            this.dataJuzgado = response.data.array[0]
            this.idJuzgado = this.dataJuzgado.id
            this.nombreJuzgado = this.dataJuzgado.nombre
            this.dataJuzgado.es_sala === '1' ? this.checkSala = true : this.checkSala = false
            this.dataJuzgado.estado === '1' ? this.checkEstado = true : this.checkEstado = false
            this.nombreJuez = this.dataJuzgado.juez
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      },
      cerrarDialog () {
        this.dialogJuzgado = false
        this.updateJuzgado = false
        this.$refs.form.reset()
      },
      updateJuzgadoData (item, fin) {
        this.nuevoJuzgado = false
        if (!item.id || !item.nombre || !item.juez || !this.dataUsuario.sede) {
          alert('La fila ' + item.id + ' no tiene todos los campos, por favor completar')
          this.incorrectos++
          if (this.incorrectos === parseInt(fin)) {
            alert('LAS ' + fin + ' FILAS DEBEN CORREGIRSE')
            this.carga = false
          }
        } else {
          const data = {
            id: item.id.toString(),
            nombre: item.nombre,
            es_sala: item.sala,
            juez: item.juez,
            estado: 1,
            sede: this.dataUsuario.sede,
          }
          this.uploadInfo(data, fin)
        }
      },
      uploadInfo (data, fin) {
        axios
          .post(`${window.__env.dataCfg.urlApiData}sede_juzgado/`, data)
          .then((r) => {
            if (!this.nuevoJuzgado) {
              this.sumaI++
              console.log(r) // eslint-disable-line
              console.log(this.sumaI) // eslint-disable-line
              console.log(this.incorrectos) // eslint-disable-line
              console.log(fin) // eslint-disable-line
              if (this.sumaI === (parseInt(fin) - this.incorrectos)) {
                this.carga = false
                alert('INFORMACIÓN DEL ARCHIVO SUBIDA, HUBIERON ' + this.incorrectos + ' FILAS INCOMPLETAS')
                this.leerJuzgados()
                this.archivoCsv2 = []
                this.incorrectos = 0
                this.sumaI = 0
              }
            } else {
              if (r.data.resp === true) {
                alert('SE REGISTRÓ CORRECTAMENTE')
                this.cerrarDialog()
                this.leerJuzgados()
              } else {
                alert('HUBO UN ERROR EN EL REGISTRO')
              }
            }
          })
          .catch((err) => {
            alert('Servicio no disponible' + err)
          })
      },
      updateResumen (e) {
        var reader = new FileReader()
        var arrayJuzgados = []
        var self = this
        reader.onload = function (e) {
          var data = new Uint8Array(e.target.result)
          var workbook = XLSX.read(data, { type: 'array' })
          const hoja1 = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[hoja1]
          arrayJuzgados = XLSX.utils.sheet_to_json(worksheet)
          for (var i = 0; i < arrayJuzgados.length; i++) {
            self.carga = true
            self.updateJuzgadoData(arrayJuzgados[i], arrayJuzgados.length)
          }
        }
        reader.readAsArrayBuffer(e)
      },
    },
  }
</script>
